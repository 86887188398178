import { mapGetters, mapActions } from 'vuex'

function createHash(mail){
  const crypto = require('crypto')
  return crypto.createHash('sha256').update(mail, 'utf8').digest('hex')
}

export default {
  computed:{
    ...mapGetters(['getItem', 'getHash']),
  },
  methods:{
    ...mapActions(['set', 'restore']),

    $_addItemId(eventId){
      const deviceId = 'kokoni-device-id-ga-hairimasu'
      const item = this.getItem ? this.getItem.split('_') : [deviceId]
      return ((!item.includes(eventId)) ? [...item, eventId] : item).join('_')
    },
/*
    async $_getPurchase(eventId, mail, passcode){
      const hashAddress = createHash(mail)
      const hashPasscode = createHash(passcode)
      return await this.wc.get('v_purchase', `w/event_id,${eventId}:hash,${hashAddress}:passcode,${hashPasscode}`).catch((err) => {
        console.log(err)
      })
    },
*/
    async $_getPurchase(eventId, mail){
      const hashAddress = createHash(mail)
      return await this.wc.get('v_purchase', `w/event_id,${eventId}:hash,${hashAddress}`).catch((err) => {
        console.log(err)
      })
    },
    $_setOrder(eventId, mail){
      this.set({
        eventId: eventId,
        hash: createHash(mail)
      })
    },
    $_setPurchase(eventId, mail){
      this.set({
        eventId: eventId,
        item: this.$_addItemId(eventId),
        buy: true
      })
      if(mail) this.set({ hash: createHash(mail) })
    },
    $_clearPurchase(eventId){
      this.set({
        eventId: '',
        buy: false,
        hash: '',
        item: ''
      })
      if(eventId) this.set({ eventId: eventId })
    }
  }
}
